import { Avatar, Box, Container, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import RoomContext from '../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  players: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  player: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function Players() {
  const classes = useStyles();
  const { players } = useContext(RoomContext);

  return (
    <Container>
      <Box display="flex">
        <Box flex={1} className={classes.players}>
          {players.map((player) => (
            <Avatar className={classes.player}>{player.name}</Avatar>
          ))}
        </Box>
      </Box>
    </Container>
  );
}

export default Players;
