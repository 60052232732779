import { Box, Button, Container, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { useContext } from 'react';
import RoomContext, { Team } from '../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  team: {
    width: '100px',
    backgroundColor: 'lightgray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    margin: '5px',
  },
}));

function Teams() {
  const classes = useStyles();
  const { connection, teams, player } = useContext(RoomContext);

  if (!player) {
    return <></>;
  }

  const handleJoinTeam = (team: Team) => {
    connection?.write('join_team', { teamName: team.name })!;
  };

  return (
    <Container>
      <Box display="flex">
        {teams.map((team) => (
          <div>
            <Box flex={1} flexDirection="column" className={classes.team}>
              <Typography variant="h6">{team.name}</Typography>
              <List dense={true}>
                {team.players.map((player) => (
                  <ListItem>
                    <ListItemText primary={`${player.name}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
            {player.team !== team && (
              <Button color="primary" variant="contained" onClick={() => handleJoinTeam(team)}>
                Join team
              </Button>
            )}
          </div>
        ))}
      </Box>
    </Container>
  );
}

export default Teams;
