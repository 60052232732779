import { Box, makeStyles, Paper } from '@material-ui/core';
import { useContext } from 'react';
import RoomContext from '../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
  },
}));

function Scoreboard() {
  const classes = useStyles();
  const { teams } = useContext(RoomContext);

  const teamsSortedByScore = [...teams];
  teamsSortedByScore.sort((team1, team2) => {
    return team2.score - team1.score;
  });

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column" height="inherit">
        <Box
          padding="10px"
          flexGrow={1}
          flex={1}
          style={{
            display: 'inline-flex',
            overflow: 'auto',
          }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            {Array.from(teamsSortedByScore).map((team, index) => (
              <Box key={team.name} display="flex" py="5px" borderBottom="1px solid lightgray">
                <Box flex={1} alignSelf="center" textAlign="center">
                  <strong>#{index + 1}</strong>
                </Box>
                <Box flex={9} textAlign="center">
                  {team.name}
                  <br />
                  Points: {team.score}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default Scoreboard;
