import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import RoomContext from '../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '50px',
    padding: '0px 10px',
  },
  flexbox: {
    alignItems: 'center',
  },
}));

function Header() {
  const classes = useStyles();
  const [timeRemaining, setTimeRemaining] = useState<number>();
  const { roundCount, drawTime, round, player } = useContext(RoomContext);

  const updateTimeRemaining = () => {
    if (!round || !round.endTime) {
      return;
    }
    setTimeRemaining(Math.max(Math.floor(round.endTime - Date.now() / 1000), 0));
  };

  useEffect(() => {
    const updateTimer = setInterval(updateTimeRemaining, 500);
    updateTimeRemaining();
    return () => {
      clearInterval(updateTimer);
    };
  }, [round]);

  if (!round || !player || !player.team) {
    return <></>;
  }

  return (
    <Paper className={classes.paper}>
      <Box display="flex" height="inherit" className={classes.flexbox}>
        <Box flex={1}>
          <Box display="flex" alignItems="center">
            <Box ml={1}>
              <Typography variant="h4">
                {round.state === 'PICK_WORDS' ? drawTime : round.state === 'DRAW' ? timeRemaining : ''}
              </Typography>
            </Box>
            <Box ml={2}>
              <Typography variant="h6">
                Round {round!.number} of {roundCount}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box flex={1}>
          {round.state === 'DRAW' ? (
            round.drawingTeam === player.team ? (
              <Typography variant="h6">Draw the word: {round.word}</Typography>
            ) : (
              <Typography variant="h6">Guess the word: {round.word}</Typography>
            )
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default Header;
