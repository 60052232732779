import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import RoomPage from './pages/room/RoomPage';
import { ApiContextProvider } from '../contexts/ApiContext';
import { RoomContextProvider } from '../contexts/RoomContext';

function App() {
  return (
    <ApiContextProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/:roomId">
            <RoomContextProvider>
              <RoomPage />
            </RoomContextProvider>
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </ApiContextProvider>
  );
}

export default App;
