import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import Chat from './Chat';
import Canvas from './Canvas';
import Scoreboard from './Scoreboard';
import Header from './Header';
import Overlay from './Overlay';
import { useContext } from 'react';
import RoomContext from '../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    width: '100%',
    maxWidth: '1400px',
    height: '700px',
  },
  box: {
    height: '100%',
  },
  boxMock: {
    height: '100%',
  },
}));

function Game() {
  const classes = useStyles();
  const { gameState, round } = useContext(RoomContext);

  if (!round) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: 'inherit', minHeight: '100vh' }}
      >
        <div className={classes.content}>
          <Box display="flex" flex={1} flexDirection="column" className={classes.box}>
            <Box mb={1}>
              <Header />
            </Box>
            <Box display="flex" flex={1} height="inherit">
              <Box flex={1} className={classes.boxMock}>
                <Scoreboard />
              </Box>
              <Box mx={1} flex={4} className={classes.boxMock}>
                <Paper className={classes.box}>
                  {gameState === 'ANNOUNCE_WINNERS' ||
                  round.state === 'PICK_WORDS' ||
                  round.state === 'ANNOUNCE_POINTS' ? (
                    <Overlay />
                  ) : (
                    <Canvas />
                  )}
                </Paper>
              </Box>
              <Box flex={1.5} className={classes.boxMock}>
                <Chat />
              </Box>
            </Box>
          </Box>
        </div>
      </Grid>
    </div>
  );
}

export default Game;
