import { Connection } from './connection';

export class ApiClient {
  async createRoom() {
    const response = await fetch('https://team-skribbl-api.harrydevane.com/create-room', { method: 'POST' });
    const data = await response.json();
    return data.roomId;
  }

  connect(): Promise<Connection> {
    return new Promise((resolve, reject) => {
      try {
        const ws = new WebSocket('wss://team-skribbl-api.harrydevane.com/connect');
        ws.onerror = reject;
        const connection = new Connection(ws);
        connection.once('connected', () => {
          ws.onerror = null;
          resolve(connection);
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}
