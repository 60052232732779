import { Box, Button, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormEvent, useContext } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import RoomContext from '../../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
}));

function JoinRoomPage() {
  const classes = useStyles();
  const { roomId } = useParams<{ roomId: string }>();
  const [name, setName] = useState<string>('');
  const { join, joinErrorMessage } = useContext(RoomContext);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    join(name);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          {joinErrorMessage && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {joinErrorMessage}
            </Alert>
          )}
          <Container>
            <Box>
              <Typography>Join room {roomId}</Typography>
            </Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box m={2}>
                <TextField label="Enter your name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </Box>
              <Box m={2}>
                <Button color="primary" variant="contained" type="submit">
                  Join Room
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default JoinRoomPage;
