import { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { Pen } from '../../utils/pen';
import { CirclePicker, ColorResult } from 'react-color';
import RoomContext from '../../contexts/RoomContext';

const MIN_STROKE_DIST_SQ = Math.pow(3, 2);

function Canvas() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [tool, setTool] = useState<string>('pen');
  const [color, setColor] = useState<number[]>([172, 274, 149]);
  const [pen, setPen] = useState<Pen>();
  const { connection, round, player } = useContext(RoomContext);
  const canDraw = round?.drawingTeam == player?.team;

  const handleColorChangeComplete = (color: ColorResult) => {
    setTool('pen');
    const rgb = color.rgb;
    setColor([rgb.r, rgb.g, rgb.b]);
  };

  const handleEraser = () => {
    setTool('eraser');
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const box = boxRef.current;
    if (!canvas || !box) {
      return;
    }
    canvas.width = box.clientWidth;
    canvas.height = box.clientHeight;
  }, [canvasRef, boxRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const pen = new Pen(canvas);

    setPen(pen);
  }, []);

  useEffect(() => {
    if (!pen) {
      return;
    }

    const canvas = canvasRef.current!;

    let painting = false;
    let startX = 0;
    let startY = 0;

    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      let x = event.pageX - canvas.offsetLeft;
      let y = event.pageY - canvas.offsetTop;
      painting = canDraw;
      startX = x;
      startY = y;
    };

    const handleMouseEnter = (event: MouseEvent) => {
      let x = event.pageX - canvas.offsetLeft;
      let y = event.pageY - canvas.offsetTop;
      startX = x;
      startY = y;
    };

    const handleMouseMove = (event: MouseEvent) => {
      let x = event.pageX - canvas.offsetLeft;
      let y = event.pageY - canvas.offsetTop;
      let distSq = Math.pow(x - startX, 2) + Math.pow(y - startY, 2);

      if (canDraw && painting && distSq > MIN_STROKE_DIST_SQ) {
        switch (tool) {
          case 'eraser':
            pen.setLineWidth(20);
            pen.setColor(255, 255, 255);
            pen.moveTo(startX, startY);
            pen.lineTo(x, y);
            break;
          default:
            if (!color) {
              return;
            }
            pen.setLineWidth(2);
            pen.setColor(color[0], color[1], color[2]);
            pen.moveTo(startX, startY);
            pen.lineTo(x, y);
            break;
        }

        startX = x;
        startY = y;
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      painting = false;
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mouseenter', handleMouseEnter);
    canvas.addEventListener('mouseleave', handleMouseMove);
    canvas.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mouseenter', handleMouseEnter);
      canvas.removeEventListener('mouseleave', handleMouseMove);
      canvas.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [pen, tool, color]);

  useEffect(() => {
    if (!pen) {
      return;
    }
    pen.writePacket = (buffer) => {
      connection!.write('draw', { buffer });
    };
    const handlePlayerDraw = ({ buffer }: { buffer: string }) => {
      pen.read(buffer);
    };
    connection?.on('player_draw', handlePlayerDraw);
    return () => {
      connection?.removeListener('player_draw', handlePlayerDraw);
    };
  }, [pen, connection]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flex={1} flexGrow={1}>
        <div ref={boxRef} style={{ height: '100%' }}>
          <canvas ref={canvasRef} id="draw-canvas" height="100%" width="100%" />
        </div>
      </Box>
      <Box p="10px">
        {canDraw && (
          <Box display="flex">
            <Box>
              <CirclePicker onChangeComplete={handleColorChangeComplete} />
            </Box>
            <Box ml={5} alignSelf="center" style={{ cursor: 'pointer' }}>
              <img src={`${process.env.PUBLIC_URL}/eraser.png`} width="50" height="50" onClick={handleEraser} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Canvas;
