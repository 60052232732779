import React, { useState } from 'react';
import { ApiClient } from '../api';

export interface ApiContextInterface {
  apiClient: ApiClient;
}

const ApiContext = React.createContext<ApiContextInterface>({
  apiClient: undefined!,
});

export const ApiContextProvider: React.FC = ({ children }) => {
  const [apiClient] = useState<ApiClient>(new ApiClient());

  const value = {
    apiClient,
  };
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export default ApiContext;
