import { v4 as uuidv4 } from 'uuid';

export function getClientId() {
  let clientId = localStorage.getItem('clientId');
  if (!clientId) {
    clientId = uuidv4();
    localStorage.setItem('clientId', clientId);
  }
  return clientId;
}

export interface Session {
  roomId: string;
}

export function getSession() {
  const json = localStorage.getItem('session');
  if (!json) {
    return undefined;
  }
  return JSON.parse(json);
}

export function setSession(session: Session) {
  localStorage.setItem('session', JSON.stringify(session));
}
