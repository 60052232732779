import { Box, List, ListItem, ListItemText, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { FormEvent } from 'react';
import RoomContext from '../../contexts/RoomContext';

interface ChatMessage {
  playerName: string;
  message: string;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
  },
}));

function Chat() {
  const classes = useStyles();

  const [message, setMessage] = useState<string>('');
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const { connection, name } = useContext(RoomContext);

  useEffect(() => {
    const handleChatSent = ({ senderName, message, color }: { senderName: string; message: string; color: string }) => {
      setChatMessages((chatMessages) => chatMessages.concat([{ playerName: senderName!, message, color }]));
    };
    connection!.on('chat_sent', handleChatSent);
    return () => {
      connection!.removeListener('chat_sent', handleChatSent);
    };
  }, [connection]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const messageToSend = message;
    setChatMessages((chatMessages) => chatMessages.concat([{ playerName: name!, message: messageToSend }]));
    setMessage('');
    connection!.write('chat', { message: messageToSend });
  };

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column" height="inherit">
        <Box
          padding="10px"
          flexGrow={1}
          flex={1}
          style={{
            display: 'inline-flex',
            overflow: 'auto',
          }}
        >
          <List dense={true}>
            {chatMessages.map((chatMessage) => (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography style={{ color: chatMessage.color ? chatMessage.color : '#000000' }}>
                      {chatMessage.playerName
                        ? chatMessage.playerName + ': ' + chatMessage.message
                        : chatMessage.message}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box padding="10px" style={{ textAlign: 'center' }}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Type your guess here..."
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </form>
        </Box>
      </Box>
    </Paper>
  );
}

export default Chat;
