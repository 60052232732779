import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useContext } from 'react';
import RoomContext from '../../contexts/RoomContext';

function Settings() {
  const { roundCount, setRoundCount } = useContext(RoomContext);
  const { drawTime, setDrawTime } = useContext(RoomContext);
  const { teamCount, setTeamCount } = useContext(RoomContext);

  return (
    <div>
      <div>
        <TextField
          id="standard-basic"
          label="Rounds"
          value={roundCount}
          onChange={(e) => setRoundCount(parseInt(e.target.value))}
        />
      </div>
      <div>
        <TextField
          id="standard-basic"
          label="Draw time in seconds"
          value={drawTime}
          onChange={(e) => setDrawTime(parseInt(e.target.value))}
        />
      </div>
      <div>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Teams
        </InputLabel>
        <Select
          labelId="standard-basic"
          id="standard-basic"
          value={teamCount.toString()}
          onChange={(e) => setTeamCount(parseInt(e.target.value as string))}
        >
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
        </Select>
      </div>
    </div>
  );
}

export default Settings;
