import { Button, Grid, Paper, Box, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ApiContext from '../../contexts/ApiContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
}));

function LandingPage() {
  const classes = useStyles();

  const history = useHistory();

  const { apiClient } = useContext(ApiContext);

  const handleCreateRoom = async () => {
    const roomId = await apiClient.createRoom();
    history.push(`/${roomId}`);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Paper elevation={3}>
            <Box p={5}>
              <Button color="primary" variant="contained" onClick={handleCreateRoom}>
                Create room
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default LandingPage;
