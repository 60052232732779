import { useContext } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import RoomContext from '../../contexts/RoomContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
  },
  overlay: {
    background: 'rgba(0,0,0,0.5)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    zIndex: 100,
    textAlign: 'center',
  },
  word: {
    background: 'black',
    padding: '10px 20px',
    margin: '5px',
  },
}));

function Overlay() {
  const classes = useStyles();
  const { connection, gameState, round, teams, player } = useContext(RoomContext);

  if (!round || !player || !player.team) {
    return <></>;
  }

  const handlePickWord = (word: string) => {
    connection?.write('pick_word', { word })!;
  };

  if (round.state === 'PICK_WORDS') {
    return (
      <div className={classes.overlay}>
        <Box display="flex" flexDirection="column">
          {round.drawingTeam === player.team ? (
            <div>
              <Box flex={1} mb="20px">
                <Typography variant="h5">Select your teams word</Typography>
              </Box>
              <Box flex={1} display="flex">
                {round.possibleWords.map((word) => (
                  <Box className={classes.word} onClick={() => handlePickWord(word)}>
                    {word}
                  </Box>
                ))}
              </Box>
            </div>
          ) : (
            <Box flex={1} mb="20px">
              <Typography variant="h5">Wait for {round.drawingTeam.name} team to select a word</Typography>
            </Box>
          )}
        </Box>
      </div>
    );
  } else if (round.state === 'ANNOUNCE_POINTS' && gameState !== 'ANNOUNCE_WINNERS') {
    const awardedPointsSortedByScore = Array.from(round.awardedPoints.entries());
    awardedPointsSortedByScore.sort((entry1, entry2) => {
      return entry2[1] - entry1[1];
    });
    return (
      <div className={classes.overlay}>
        <Box display="flex" flexDirection="column">
          <div>
            <Box flex={1} mb="20px">
              <Typography variant="h4">The word was: {round.word}</Typography>
            </Box>
            <Box flex={1} mb="20px">
              {awardedPointsSortedByScore.map(([teamName, points]) => (
                <Typography variant="h5">{teamName + ' +' + points}</Typography>
              ))}
            </Box>
          </div>
        </Box>
      </div>
    );
  } else if (gameState === 'ANNOUNCE_WINNERS') {
    const teamsSortedByScore = [...teams];
    teamsSortedByScore.sort((team1, team2) => {
      return team2.score - team1.score;
    });

    return (
      <div className={classes.overlay}>
        <Box display="flex" flexDirection="column">
          <div>
            <Box flex={1} mb="20px">
              <Typography variant="h4">Results</Typography>
            </Box>
            <Box flex={1} mb="20px">
              {Array.from(teamsSortedByScore).map((team, index) => (
                <Box key={team.name} display="flex" py="5px">
                  <Box flex={1} alignSelf="center" textAlign="center">
                    <strong>#{index + 1}</strong>
                  </Box>
                  <Box flex={9} textAlign="center">
                    {team.name}
                    <br />
                  </Box>
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Overlay;
