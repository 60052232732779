import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Connection } from '../connection';
import { JoinRoomResponse } from '../proto';
import { getClientId, getSession, setSession } from '../utils/utils';
import ApiContext from './ApiContext';

export interface RoomContextInterface {
  join: (name: string) => void;
  joinErrorMessage: string;
  loadingStage: number;
  setLoadingStage: (loadingStage: number) => void;
  connection?: Connection;
  gameState: string;
  roundCount: number;
  setRoundCount: (roundCount: number) => void;
  drawTime: number;
  setDrawTime: (drawTime: number) => void;
  teamCount: number;
  setTeamCount: (teamCount: number) => void;
  players: Player[];
  player: Player | undefined;
  setPlayers: (players: Player[]) => void;
  teams: Team[];
  setTeams: (teams: Team[]) => void;
  round: Round | undefined;
  name: string | undefined;
}

const RoomContext = React.createContext<RoomContextInterface>({
  join: () => undefined,
  joinErrorMessage: '',
  loadingStage: 0,
  setLoadingStage: () => undefined,
  connection: undefined,
  gameState: '',
  roundCount: 0,
  setRoundCount: () => undefined,
  drawTime: 0,
  setDrawTime: () => undefined,
  teamCount: 0,
  setTeamCount: () => undefined,
  players: [],
  setPlayers: () => undefined,
  player: undefined,
  teams: [],
  setTeams: () => undefined,
  round: undefined,
  name: '',
});

export interface Player {
  name: string;
  team?: Team;
}

export interface Team {
  name: string;
  players: Player[];
  score: number;
}

export interface Round {
  number: number;
  drawingTeam: Team;
  possibleWords: string[];
  word: string;
  endTime: number;
  awardedPoints: Map<string, number>;
  state: string;
}

export const RoomContextProvider: React.FC = ({ children }) => {
  const { roomId } = useParams<{ roomId: string }>();

  const { apiClient } = useContext(ApiContext);

  const [loadingStage, setLoadingStage] = useState<number>(0);
  const [joinErrorMessage, setJoinErrorMessage] = useState<string>('');
  const [connection, setConnection] = useState<Connection>();
  const [connected, setConnected] = useState<boolean>();
  const [gameState, setGameState] = useState<string>('');
  const [roundCount, setRoundCount] = useState<number>(3);
  const [drawTime, setDrawTime] = useState<number>(80);
  const [teamCount, setTeamCount] = useState<number>(1);
  const [name, setName] = useState<string>();
  const [players, setPlayers] = useState<Player[]>([]);
  const [player, setPlayer] = useState<Player>();
  const [teams, setTeams] = useState<Team[]>([]);
  const [round, setRound] = useState<Round>();

  const join = async (name?: string) => {
    try {
      const connection = await apiClient.connect();
      setConnection(connection);
      const response: JoinRoomResponse = await connection.request('join_room', {
        clientId: getClientId(),
        roomId,
        name,
      });
      if (!response.success) {
        setJoinErrorMessage(response.errorMessage);
        setLoadingStage(1);
        return;
      }
      // Hackfix Cloudflare websocket timeouts
      setInterval(() => {
        connection.write('keepalive', { time: Date.now() });
      }, 1000);
    } catch (e) {
      setJoinErrorMessage('Unable to connect to room');
      setLoadingStage(1);
      return;
    }
  };

  const updateRoundCount = (roundCount: number) => {
    setRoundCount(roundCount);
    connection?.write('update_settings', { roundCount, drawTime, teamCount });
  };

  const updateDrawTime = (drawTime: number) => {
    setDrawTime(drawTime);
    connection?.write('update_settings', { roundCount, drawTime, teamCount });
  };

  const updateTeamCount = (teamCount: number) => {
    setTeamCount(teamCount);
    connection?.write('update_settings', { roundCount, drawTime, teamCount });
  };

  useEffect(() => {
    if (!connection) {
      return;
    }

    const handleGameState = ({ gameState }: { gameState: string }) => {
      setGameState(gameState);
      if (!connected) {
        setSession({ roomId });
        setLoadingStage(2);
        setConnected(true);
      }
    };

    const handleSettings = ({
      roundCount,
      drawTime,
      teamCount,
    }: {
      roundCount: number;
      drawTime: number;
      teamCount: number;
    }) => {
      setRoundCount(roundCount);
      setDrawTime(drawTime);
      setTeamCount(teamCount);
    };

    const handlePlayerJoin = ({ name, isSelf }: { name: string; isSelf: boolean }) => {
      if (players.find((player) => player.name === name)) {
        return;
      }
      // React setState is async, so use the callback version to prevent
      // this getting out of sync
      const player = { name };
      setPlayers((players) => players.concat([player]));
      if (isSelf) {
        setName(name);
        setPlayer(player);
      }
    };

    const handleTeamAdd = ({ name }: { name: string }) => {
      const team: Team = { name, players: [], score: 0 };
      setTeams((teams) => teams.concat([team]));
    };

    const handleTeamRemove = ({ name }: { name: string }) => {
      setTeams((teams) => teams.filter((team) => team.name !== name));
    };

    const handlePlayerTeam = ({ playerName, teamName }: { playerName: string; teamName: string }) => {
      setPlayers((players) => {
        const player = players.find((player) => player.name === playerName);
        if (!player) {
          return players;
        }
        const team = teams.find((team) => team.name === teamName);
        if (!team) {
          return players;
        }
        const oldTeam = player.team;
        if (oldTeam) {
          oldTeam.players = oldTeam.players.filter((otherPlayer) => otherPlayer !== player);
        }
        team.players.push(player);
        player.team = team;
        setTeams([...teams]);
        return [...players];
      });
    };

    const handleRoundState = ({
      roundNumber,
      drawingTeamName,
      possibleWords,
      word,
      endTime,
      awardedPoints,
      state,
    }: {
      roundNumber: number;
      drawingTeamName: string;
      possibleWords: string[];
      word: string;
      endTime: number;
      awardedPoints: any;
      state: string;
    }) => {
      const drawingTeam = teams.find((team) => team.name === drawingTeamName);
      if (!drawingTeam) {
        return;
      }
      setRound({
        number: roundNumber,
        drawingTeam,
        possibleWords,
        word,
        endTime,
        awardedPoints: new Map(Object.entries(awardedPoints)),
        state,
      });
    };

    const handleTeamScore = ({ teamName, score }: { teamName: string; score: number }) => {
      setTeams((teams) => {
        const team = teams.find((team) => team.name === teamName);
        if (!team) {
          return teams;
        }
        team.score = score;
        return [...teams];
      });
    };

    const handleClose = () => {
      setLoadingStage(0);
      join();
    };

    connection.on('game_state', handleGameState);
    connection.on('settings', handleSettings);
    connection.on('player_join', handlePlayerJoin);
    connection.on('team_add', handleTeamAdd);
    connection.on('team_remove', handleTeamRemove);
    connection.on('player_team', handlePlayerTeam);
    connection.on('round_state', handleRoundState);
    connection.on('team_score', handleTeamScore);
    connection.on('close', handleClose);

    return () => {
      connection.removeListener('game_state', handleGameState);
      connection.removeListener('settings', handleSettings);
      connection.removeListener('player_join', handlePlayerJoin);
      connection.removeListener('team_add', handleTeamAdd);
      connection.removeListener('team_remove', handleTeamRemove);
      connection.removeListener('player_team', handlePlayerTeam);
      connection.removeListener('round_state', handleRoundState);
      connection.removeListener('team_score', handleTeamScore);
      connection.removeListener('close', handleClose);
    };
  }, [connection, connected, players, teams, round]);

  useEffect((): void => {
    const session = getSession();
    if (!session || session.roomId !== roomId) {
      setLoadingStage(1);
      return;
    }
    join();
  }, []);

  const value = {
    join,
    joinErrorMessage,
    loadingStage,
    setLoadingStage,
    connection,
    gameState,
    roundCount,
    setRoundCount: updateRoundCount,
    drawTime,
    setDrawTime: updateDrawTime,
    teamCount,
    setTeamCount: updateTeamCount,
    players,
    setPlayers,
    player,
    teams,
    setTeams,
    round,
    name,
  };
  return <RoomContext.Provider value={value}>{children}</RoomContext.Provider>;
};

export default RoomContext;
