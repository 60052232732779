import { EventEmitter } from 'events';

interface ConnectionCallback {
  resolve: Function;
  reject: Function;
  timeout: NodeJS.Timeout;
}

export class Connection extends EventEmitter {
  ws: WebSocket;
  callbacks: Map<number, ConnectionCallback> = new Map();
  lastId = 0;

  constructor(ws: WebSocket) {
    super();
    this.ws = ws;
    this.ws.onopen = () => this.emit('connected');
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onclose = (event: CloseEvent) => this.emit('close', event.code, event.reason);
  }

  write(messageType: string, data: object) {
    this.ws.send(
      JSON.stringify({
        messageType,
        data,
      })
    );
  }

  request<T>(requestType: string, data: object): Promise<T> {
    const id = (this.lastId += 1);
    this.ws.send(
      JSON.stringify({
        id,
        requestType,
        data,
      })
    );
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Request timed out'));
        this.ws.close();
      }, 30 * 1000);
      this.callbacks.set(id, { resolve, reject, timeout });
    });
  }

  private onMessage(event: MessageEvent) {
    const data = JSON.parse(event.data);
    if (data.id) {
      const callback = this.callbacks.get(data.id);
      if (callback) {
        if (data.error) {
          callback.reject(new Error(data.error));
        } else {
          callback.resolve(data.data);
        }
        clearTimeout(callback.timeout);
        this.callbacks.delete(data.id);
      }
    } else {
      this.emit(data.messageType, data.data);
    }
  }

  close() {
    this.ws.close();
  }
}
