import JoinRoomPage from './JoinRoomPage';
import Lobby from '../../lobby/Lobby';
import Game from '../../game/Game';
import RoomContext from '../../../contexts/RoomContext';
import { useContext } from 'react';

function RoomPage() {
  const { loadingStage, gameState } = useContext(RoomContext);

  if (loadingStage === 1) {
    return <JoinRoomPage />;
  }
  if (loadingStage === 2) {
    if (gameState === 'LOBBY') {
      return <Lobby />;
    } else {
      return <Game />;
    }
  }
  return <></>;
}

export default RoomPage;
