import { Button, Box, Grid, makeStyles } from '@material-ui/core';
import Settings from './Settings';
import Players from './Players';
import { useContext, useState } from 'react';
import { StartGameResponse } from '../../proto';
import RoomContext from '../../contexts/RoomContext';
import { Alert, AlertTitle } from '@material-ui/lab';
import Teams from './Teams';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    width: '100%',
    maxWidth: '1400px',
    height: '700px',
  },
  box: {
    height: '100%',
  },
  boxMock: {
    height: '100%',
  },
}));

function Lobby() {
  const classes = useStyles();
  const [startErrorMessage, setStartErrorMessage] = useState<string>('');
  const { connection } = useContext(RoomContext);

  const handleStartGame = async () => {
    const response: StartGameResponse = await connection?.request('start_game', {})!;
    if (!response.success) {
      setStartErrorMessage(response.errorMessage);
      return;
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: '100%', minHeight: '100vh' }}
      >
        <div className={classes.content}>
          <Box display="flex" className={classes.box}>
            <Box flex={1} m={1} className={classes.boxMock}>
              <Settings />
              <Button color="primary" variant="contained" onClick={handleStartGame}>
                Start game
              </Button>
              {startErrorMessage && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {startErrorMessage}
                </Alert>
              )}
            </Box>
            <Box flex={1} m={1} className={classes.boxMock}>
              <Players />
              <Teams />
            </Box>
          </Box>
        </div>
      </Grid>
    </div>
  );
}

export default Lobby;
